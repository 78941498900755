enum Source {
    RelatedVacancies = 'related_vacancies_on_vacancy_page',
    VacancySearch = 'vacancies',
    VacancySearchMap = 'vacancies_on_search_map',
    ApplicantNegotiations = 'applicant_negotiations',
    RecommendedVacanciesOnMain = 'recommended_vacancies_on_main',
    EmployerRelatedVacancies = 'related_employer_vacancies_on_vacancy_page',
    VacanciesOfTheDay = 'vacancies_of_the_day',
    EmployerVacancy = 'employer_vacancy',
    IndexAnonymous = 'index_anonymous',
    EmployerVacancySearch = 'employer_vacancy_search',
}

export default Source;
