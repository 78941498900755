/**
 * Конвертирует массив строк в читаемый формат: "[first], [seconds] и/или [last]"
 * @property {Array} values - массив строк
 * @property {String} lastValueSeparatorTrl - строка-разделитель для последнего элемента ('и'/'или')
 * @returns {String}
 */
export const arrayToHumanReadableList = (values: string[], lastValueSeparatorTrl: string): string => {
    let humanReadableList;
    if (values.length > 1) {
        humanReadableList = `${values.slice(0, -1).join(', ')} ${lastValueSeparatorTrl} ${values.slice(-1)[0]}`;
    } else {
        humanReadableList = values.join(', ');
    }
    return humanReadableList;
};
