import { useEffect, useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';

const TrlKeys = {
    addLetter: 'vacancy.response.popup.lastLetter',
    lastLetterUsed: 'vacancy.response.popup.letterWasUsed',
};

const LastLetterLink: TranslatedComponent<{ setLetter: (v: string) => void }> = ({ setLetter, trls }) => {
    const lastLetter = useSelector((state) => state.lastVacancyResponseInfo?.letter);
    const [isLastLetterUsed, toggleLastLetterUsed] = useToggleState(false);
    const linkRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (linkRef.current) {
            Analytics.sendHHEventElementShown(linkRef.current, {
                name: 'use_letter_from_previous_response',
            });
        }
    }, []);

    if (!lastLetter) {
        return null;
    }

    const handleClick = () => {
        toggleLastLetterUsed();
        setLetter(lastLetter);
        Analytics.sendHHEventButtonClick('use_letter_from_previous_response');
    };

    return (
        <div className="vacancy-response-popup-letter">
            <VSpacing base={2} />
            {isLastLetterUsed ? (
                <Text importance={TextImportance.Tertiary}>{trls[TrlKeys.lastLetterUsed]}</Text>
            ) : (
                <Link
                    appearance={LinkAppearance.Pseudo}
                    onClick={handleClick}
                    data-qa="vacancy-response-use-last-letter"
                    ref={linkRef}
                >
                    {trls[TrlKeys.addLetter]}
                </Link>
            )}
        </div>
    );
};

export default translation(LastLetterLink);
