import TranslateGuard from 'bloko/blocks/translateGuard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';

export enum ResponseError {
    Unknown = 'unknown',
    LetterRequired = 'letter-required',
    TestRequired = 'test-required',
    TestServiceDown = 'test-service-down',
    NegotiationsLimitExceeded = 'negotiations-limit-exceeded',
    TooLongLetter = 'too-long-letter',
    ChangeResumeVisibilityDenied = 'change-resume-visibility-denied',
    AlreadyApplied = 'alreadyApplied',
    SpamDetected = 'SPAM_DETECTED',
    ResourcePolicyViolation = 'RESOURCE_POLICY_VIOLATION',
    InappropriateLanguage = 'INAPPROPRIATE_LANGUAGE',
}

const TrlKeys = {
    [ResponseError.Unknown]: 'vacancy.response.popup.unknown.error',
    [ResponseError.LetterRequired]: 'vacancy.response.popup.letter.error',
    [ResponseError.TestRequired]: 'vacancy.response.popup.test.error',
    [ResponseError.TestServiceDown]: 'vacancy.response.popup.testServiceDown.error',
    [ResponseError.NegotiationsLimitExceeded]: 'vacancy.response.popup.negotiationsLimitExceeded.error',
    [ResponseError.TooLongLetter]: 'vacancy.response.popup.letter.tooLong',
    [ResponseError.ChangeResumeVisibilityDenied]: 'vacancy.response.popup.changeResumeVisibilityDenied.error',
    [ResponseError.AlreadyApplied]: 'vacancy.response.popup.alreadyApplied.error',
    [ResponseError.SpamDetected]: 'vacancy.response.popup.spamDetected',
    [ResponseError.ResourcePolicyViolation]: 'vacancy.response.popup.resourcePolicyViolation',
    [ResponseError.InappropriateLanguage]: 'vacancy.response.popup.inappropriateLanguage',
};

interface VacancyResponseErrorBaseProps {
    code?: ResponseError;
    letterMaxLength?: number;
}

type VacancyResponseErrorProps =
    | VacancyResponseErrorBaseProps
    | {
          code: ResponseError.TooLongLetter;
          letterMaxLength: number;
      };
const VacancyResponseError: TranslatedComponent<VacancyResponseErrorProps> = ({
    trls,
    code = ResponseError.Unknown,
    letterMaxLength,
}) => {
    if (!code) {
        return null;
    }
    return (
        <TranslateGuard>
            {code === ResponseError.TooLongLetter
                ? format(trls[TrlKeys[code]], { '{0}': letterMaxLength ?? 0 })
                : trls[TrlKeys[code]]}
        </TranslateGuard>
    );
};

export default translation(VacancyResponseError);
