import { useCallback } from 'react';

import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import { useSelector } from 'src/hooks/useSelector';
import { AppDispatch } from 'src/models/appDispatch';
import { ResponseQuestionItem, vacancyResponseQuestionsAction } from 'src/models/vacancyResponseQuestions';

const LOCALSTORAGE_BASE_KEY = 'vacancy_response_questions_store';
const LSCache: Record<string, ResponseQuestionItem | null> = {};

const getSavedQuestion = (vacancyId: number): ResponseQuestionItem | null => {
    if (vacancyId === undefined) {
        return null;
    }
    if (vacancyId in LSCache) {
        return LSCache[vacancyId];
    }
    const value = LocalStorageWrapper.getItem(`${LOCALSTORAGE_BASE_KEY}_${vacancyId}`);
    let result = null;
    if (value) {
        try {
            result = JSON.parse(value) as ResponseQuestionItem;
        } catch (_) {} // eslint-disable-line no-empty
    }
    LSCache[vacancyId] = result;
    return result;
};

interface SaveVacancyResponseQuestion {
    vacancyId: number;
    question: string;
    questionId: string;
    dispatch: AppDispatch;
}
export const saveVacancyResponseQuestion = ({
    vacancyId,
    question,
    questionId,
    dispatch,
}: SaveVacancyResponseQuestion): void => {
    const value = {
        id: questionId,
        value: question,
    };
    LocalStorageWrapper.setItem(`${LOCALSTORAGE_BASE_KEY}_${vacancyId}`, JSON.stringify(value));
    dispatch(
        vacancyResponseQuestionsAction({
            [String(vacancyId)]: value,
        })
    );
};

const useGetResponseQuestion = (): ((vacancyId: number) => ResponseQuestionItem | null) => {
    const vacancyResponseQuestions = useSelector((state) => state.vacancyResponseQuestions);
    return useCallback(
        (vacancyId: number) => {
            return vacancyResponseQuestions[String(vacancyId)] || getSavedQuestion(vacancyId);
        },
        [vacancyResponseQuestions]
    );
};

export default useGetResponseQuestion;
